import { TooltipProps } from "@blueprintjs/core";
import { HexColor, IconType } from "@hex/common";
import { rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components";

import { HexTooltip } from "../../../hex-components";
import { IconsToNames } from "../../../hex-components/IconPicker.js";
import { MinimalStatus } from "../../../hooks/useProjectLabelsForHex";
import { ChexagonIcon } from "../../icons/CustomIcons.js";

import { StatusIcon } from "./OrganizationIcons";

const StatusName = styled.div``;

export const StatusLabelWrapper = styled.div<{
  $condensed?: boolean;
  $minimal?: boolean;
  $muted?: boolean;
}>`
  display: flex;
  align-items: center;
  min-width: 0;

  color: ${({ $muted, theme }) =>
    $muted ? theme.fontColor.MUTED : theme.fontColor.DEFAULT};

  ${({ $condensed }) =>
    $condensed
      ? css`
          height: 18px;
          font-size: ${({ theme }) => theme.fontSize.SMALL};
          gap: 2px;

          ${StatusIcon} {
            height: 10px;
            width: 10px;
          }
        `
      : css`
          height: 22px;
          font-size: ${({ theme }) => theme.fontSize.SMALL};
          padding: 4px 6px;
          gap: 4px;
        `}

  ${({ $condensed, $minimal }) =>
    $minimal &&
    css`
      justify-content: center;

      ${$condensed
        ? css`
            width: 18px;

            svg {
              height: 14px;
              width: 14px;
            }
          `
        : css`
            width: 22px;
          `}
    `};
`;

const EndorsedStatusLabelWrapper = styled(StatusLabelWrapper)<{
  $condensed?: boolean;
  $minimal?: boolean;
  $color: HexColor;
}>`
  ${({ $color, theme }) => `
    background-color: ${rgba(theme.statusCategoryColor[$color], 0.1)};
    color: ${theme.statusCategoryColor[$color]};

    svg {
      color: ${rgba(theme.statusCategoryColor[$color], 0.85)};
    }
  `}

  border-radius: 20px;

  align-self: center;

  ${({ $condensed, $minimal }) =>
    !$minimal &&
    ($condensed
      ? css`
          padding: 1px 5px 1px 3px;
          gap: 2px;

          svg {
            height: 14px;
            width: 14px;
          }
        `
      : css`
          padding: 4px 6px 4px 4px;
          gap: 2px;
        `)}

  ${({ $muted }) =>
    $muted &&
    css`
      background: none;
      padding-left: 0px;
    `}
`;

const StatusTag: React.ComponentType<{
  color: HexColor;
  children: string;
  endorsed: boolean;
  icon: IconType | null;
  condensed?: boolean;
  minimal?: boolean;
  muted?: boolean;
  className?: string;
}> = React.memo(function StatusTag({
  children,
  className,
  color,
  condensed,
  endorsed,
  icon,
  minimal,
  muted,
}) {
  if (endorsed) {
    return (
      <EndorsedStatusLabelWrapper
        $color={color}
        $condensed={condensed}
        $minimal={minimal}
        $muted={muted}
        className={className}
      >
        {icon != null ? IconsToNames[icon] : <ChexagonIcon />}
        {!minimal && <StatusName>{children}</StatusName>}
      </EndorsedStatusLabelWrapper>
    );
  }

  return (
    <StatusLabelWrapper
      $condensed={condensed}
      $minimal={minimal}
      $muted={muted}
      className={className}
    >
      <StatusIcon $color={color} />
      {!minimal && <StatusName>{children}</StatusName>}
    </StatusLabelWrapper>
  );
});

export interface StatusLabelProps {
  className?: string;
  status: Record<string, unknown> & Omit<MinimalStatus, "id" | "inLibrary">;
  /** Condensed form with smaller icon & less padding */
  condensed?: boolean;
  /** Icon-only form */
  minimal?: boolean;
  muted?: boolean;
  descriptionTooltipProps?: Omit<TooltipProps, "content">;
}

export const StatusLabel: React.ComponentType<StatusLabelProps> = React.memo(
  function StatusLabel({
    className,
    condensed,
    descriptionTooltipProps,
    minimal,
    muted,
    status: { color, description, endorsed, icon, name },
  }: StatusLabelProps) {
    return (
      <HexTooltip
        content={
          <>
            {minimal && <div>{name}</div>}
            {description}
          </>
        }
        disabled={
          !minimal && (description == null || description.trim().length === 0)
        }
        placement="bottom"
        {...descriptionTooltipProps}
      >
        <StatusTag
          className={className}
          color={color}
          condensed={condensed}
          endorsed={endorsed}
          icon={icon}
          minimal={minimal}
          muted={endorsed ? false : muted}
        >
          {name}
        </StatusTag>
      </HexTooltip>
    );
  },
);
